import React from "react";
import styled from "styled-components";

const Oops = styled.div`
  .center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  h1 {
    font-size: 4em;
  }
  p {
    margin-top: 30px;
  }
`;

export default function fourOhFour() {
  return (
    <Oops>
      <div className="center">
        <h1 className="mark">404</h1>
        <p>यह पृष्ट उपलब्द नहीं है</p>
      </div>
    </Oops>
  );
}
